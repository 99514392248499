body {
  margin: 0;
  line-height: normal;
  font-family: var(--font-fslola-normal);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  color: #071e2e;
  text-align: left;
  background: #071e2e radial-gradient(circle, #496576, #071e2e);

  background-repeat: no-repeat;
}

#video-background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  z-index: -100; /* Behind the content */
  overflow: hidden;
}

video#christmasVideo {
  width: 100%;
  height: 100vh;
  object-fit: cover; /* Cover the full area */
}

li, .group-main p {
  padding-bottom: 0.7rem;
}
.green-video, .video {
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  width: 95%;
}
.green-icon {
  width: 80px;
  display: block;
  margin: 0 auto;
  padding-top: 60px;
  margin-bottom: 25px;
  height: 1px;
  opacity: 0;
  transition: all 2s ease-in-out;
}
.green-info {
  width: 95%;
  font-family: var(--font-fslola-italic);
  font-size: var(--font-size-base);
  color: var(--color-white);
  text-align: center;
  margin-bottom: 25px;
  padding-top: 25px;
}
.path-45-icon {
  height: calc(100% - 32px);
  width: 100%;
}
.group-div,
.group-icon,
.path-45-icon {
  bottom: 0;
  left: 0;
}
.group-icon {
  height: 100%;
  width: 100%;
}
.div,
.sternschnuppenkunde-i {
  cursor: auto;
}
.accordion span, .btn-title {
  vertical-align: middle;
  height: 46px;
  display: table-cell;
  transition: all 0.5s ease-out;
}

.accordion:hover {
  color: #009bac;
}
.div {
  display: block;
  font-size: var(--font-size-sm);
}
.div1,
.fnf-erstaunliche-fakten-ber {
  letter-spacing: -.69px;
  width: 15px;
  padding: 0 20px;
  float: right;
  font-size: var(--font-size-title);
  transform: rotate(90deg);
  font-family: var(--font-fslola-bold);
  margin-top: -8px;
}
.fnf-erstaunliche-fakten-ber {
  display: block;
  font-size: var(--font-size-base);
  letter-spacing: -0.28px;
  font-weight: 700;
}
.die-sternschnuppe,
.div2 {
  letter-spacing: -0.69px;
  font-weight: 300;
  display: inline-block;
  padding: 0 5px 0 10px;
}
.die-sternschnuppe {
  display: block;
  font-size: var(--font-size-base);
  letter-spacing: -0.28px;
  font-weight: 700;
}
.group-div1 > p, .group-main > p {
  font-size: var(--font-size-base);
}
.div3 {
  display: block;
  letter-spacing: -0.69px;
  font-weight: 300;
}
.div3,
.div4,
.div5,
.geminiden-2022 {
  display: inline-block;
}
.geminiden-2022 {
  display: block;
  font-size: var(--font-size-base);
  letter-spacing: -0.28px;
  font-weight: 700;
}
.div5 {
  color: var(--color-white);
}
.group-div2 {
  display: block;
  width: 25.88px;
  height: 11px;
  font-size: var(--font-size-sm);
}
.ber-den-fotografen,
.div6 {
  display: inline-block;
}
.ber-den-fotografen {
  display: block;
  font-size: var(--font-size-base);
  letter-spacing: -0.28px;
  font-weight: 700;
}
.div6 {
  display: inline-block;
  transform: rotate(270deg);
  margin-top: 0;
  /* transform-origin: 60%; */
}
.group-icon2 {
  width: 100%;
  display: block;
}
.weitere-infos-wwwmarkuseiche {
  font-size: .75rem !important;
}
.der-sternefotograf-markus, .panel p {
  margin: 0;
  font-size: var(--font-size-accordion);
}
.der-sternefotograf-markus-eich {
  width: 100%;
  font-size: 20px;
  letter-spacing: -0.18px;
  display: inline-block;
}
.group-main {
  width: 100%;
  background-color: var(--color-white);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-indigo);
  font-family: var(--font-fslola-italic);
  display: block;
}
.group-main a {
  text-decoration: none;
  color: var(--color-indigo);
}
.group-main a:hover {
  text-decoration: underline;
  color: var(--color-indigo);
}
.group-div1 {
  max-width: 866px;
  margin: 2rem auto 0;
  display: block;
  position: relative;
  width: 75%;
}
.web-2600-1-main {
  width: 100%;
  font-size: var(--font-size-lg);
  color: var(--color-indigo);
  font-family: var(--font-fslola-italic);
}
#after-video {
  opacity: 1;
  transition: all 0.8s ease-in-out;
}
.green-video.absolute {
  position: absolute;
}

#custom-end-screen {
  z-index: 100;
  position: absolute;

}


@media (max-width: 575.98px) {
  #custom-end-screen {
    margin: 0px 15px auto;
    right: 0px;
  }
}

@media (min-width: 576px) and (max-width: 1157px) {
  #custom-end-screen {
    width: 100%;
    padding-right: 30px;
  }
  #custom-end-screen img {
    width: 100%;
  }
}

@media (min-width: 1201px) and (max-width: 1425px) {
  #custom-end-screen {
    width: 97.5%;
  }
  #custom-end-screen img {
    width: 100%;
  }
}
.main-empty footer {
  background-position: bottom;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  z-index: 1000;
}
.footer__nav {
  padding-inline-start: 0;
}
.greeting-text {
  text-align: center;
  width: 100%;
  color: #fff;
}
.greeting-text p {
  max-width: 1200px;
  width: 95%;
  font-size: var(--font-size-base);
  margin-top: 40px;
  display: block;
  text-align: left;
}
footer * {
  font-family: var(--font-fslola-normal);
  list-style: none;
}
.footer__top--content {
  position: relative;
}
.content__headline {
  margin-bottom: 0;
  margin-top: 0;
}
.content__headline+[class*='content__'] {
  margin-top: 0;
}
.footer__row--top {
  padding: 0;
}
.footer__row--bottom {
  align-items: flex-end;
  padding-bottom: 10px;
}
.footer__col--claim {
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 15px;
}
span.claim__piece {
  font-family: var(--font-fslola-bold);
}
.claim {
  margin-bottom: 0;
}
.icon-collection__item a[target='_blank'] {
  background: none;
  line-height: .1;
  padding: 0;
}
.footer__col--claim .claim__piece {
  color: #fff;
  font-size: 22px;
  letter-spacing: 0;
}
.footer__col--logo-collection {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
}
.logo-collection__item {
  flex-basis: auto;
  margin: 15px;
  max-width: calc(50% - 40px);
}
.logo-collection__item img {
  height: auto;
  max-height: 70px;
  width: auto;
}
.icon-collection__item {
  display: flex;
  float: left;
  margin: 0 30px 30px 0;
  padding-top: 48px;
}
.icon-collection__item a img {
  height: auto;
  transition: all 0.2s ease-in-out;
  vertical-align: baseline;
  width: auto;
}
.footer__col--icon-collection {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
}
.video-collection__item {
  display: flex;
  float: left;
  margin: 0 30px 30px 0;
  padding-top: 48px;
}
.video-collection__item a[target='_blank'] {
  background: none;
  line-height: .1;
  padding: 0;
}
.video-collection__item a img {
  height: auto;
  transition: all 0.2s ease-in-out;
  vertical-align: baseline;
  width: auto;
}
.footer__wrapper {
  margin-top: 15%;
}
.footer__wrapper .container {
  width: 100%;
  margin: 0 auto;
}
p {
  margin: 0 auto;
}
.footer__nav--title {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.263 18.263'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.6' d='M1.131 9.132l8 8 8-8'/%3E%3C/svg%3E");
  background-position: calc(100% - 20px) calc(50% - 3px);
  background-size: 19px 19px;
  background-repeat: no-repeat;
  border-bottom: 1px solid #fff;
  color: #fff;
  margin-bottom: 0;
  padding: 20px 20px 18px;
  text-align: left;
  width: 100%;
  font-family: var(--font-fslola-medium);
}
.footer__nav--sub {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer__nav--sub.open {
  display: block;
}
.footer__nav--sub-item {
  margin-bottom: 4px;
  padding-bottom: 0;
}
.footer__nav--sub-item a {
  font-size: 16px;
  line-height: 1.3125;
  letter-spacing: .02em;
  color: #fff;
  text-decoration: none;
}
.footer__nav--title, .teaser__title, .nav__call-to-action--title, .news-menu-view .item, .news-menu-view .item a {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.375;
  letter-spacing: .05em;
  text-transform: uppercase;
}
.accordion, .sternschnuppenkunde-i {
  background-color: #fff;
  color: #003e47;
  cursor: pointer;
  margin: 20px 0 0;
  padding: 3px 3px 3px 21px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  display: block;
  font-size: var(--font-size-base);
  font-family: var(--font-fslola-bold-italic);
  letter-spacing: -0.28px;
  transition: 0.4s;
}
.active, .accordion:hover {
  background-color: #fff;

}
.panel {
  padding: 0 21px;
  display: block;
  background-color: white;
  overflow: hidden;
  height: auto;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}
.panel.active {
  max-height: 300vh;
}
.sternschnuppenkunde-i {
  font-size: var(--font-size-title) !important;
  font-family: var(--font-fslola-italic);
  letter-spacing: -0.39px;
  padding-left: 21px;
  cursor: auto;
}
@media (min-width: 992px) {
  .footer__nav--title, .teaser__title, .nav__call-to-action--title, .news-menu-view .item, .news-menu-view .item a {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2942;
    letter-spacing: .05em;
  }
}
.footer__nav--sub-item a:hover, .footer__nav--sub-item a:focus {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .footer__nav--title {
    background-image: none;
    border: 0;
    margin-bottom: 1em;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .icon-collection__item {
      padding-top: 0;
  }
  .footer__icon-collection--item:first-child {
    margin-left: 1px;
  }
  .footer__col--claim {
    justify-content: flex-start;
  }
}
img {
  max-width: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}
.btn-play, .btn-replay, .btn-pause {
  cursor: pointer;
  z-index: 10000;
  width: 5%;
  position: absolute;
  left: 48.5%;
  right: 45.5%;
}
.btn-replay, .btn-pause {
  display: none;
}
video, .green-video, #mep_0 {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
#mep_0 {
  z-index: 1000;
}
#after-video-slider {
  transition: all 1s ease-in-out;
  overflow: hidden;
}
.sliding-text {
  font-family: var(--font-fslola-normal);
  font-size: var(--font-size-normal);
  color: #fff;
  width: 50%;
  max-width: 1200pc;
  height: 30%;
  padding: 20% 5% 5%;
  opacity: 0;
  position: absolute;
  top: 30%;
  left: 25%;
  right: 25%;
  overflow: hidden;
  transition: all 1s ease-in-out;
}
body {
  overflow-x: hidden;
}
section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
.sliding-div.video {
  font-family: var(--font-fslola-italic);
  font-size: var(--font-size-base);
  color: var(--color-white);
  padding-bottom: 1rem;
  overflow: hidden;
  transition: all 1s ease-in-out;
}
.sliding-div.video div {
  text-align: left !important;
  margin: 0 auto;
}
.dark {
  background: transparent !important;
  color: #fff !important;
  display: block;
}
ol, ul {
  padding-left: 18px;
}
@media (max-width: 1424px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
      max-width: 1344px;
  }
}
@media (max-width: 1200px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1140px;
  }
}
@media (max-width: 992px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm, .container-md, .container-lg {
      max-width: 960px;
  }
}
@media (max-width: 768px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm, .container-md {
      max-width: 720px;
  }
}
@media (max-width: 575.98px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm {
      max-width: 540px;
  }
  .sternschnuppenkunde-i {
    font-size: var(--font-size-base) !important;
  }
  .group-div1 > p, .group-main > p {
    font-size: 1rem;
  }
  .accordion, .sternschnuppenkunde-i {
    font-size: var(--font-size-normal);
    padding-left: 9px;
  }
  .group-div1 {
    width: 95%;
    transition: all 0.8s ease-in-out;
  }
  .group-main {
    width: 100%;
    padding: 5px;
  }
  .panel {
    padding: 0 4px;
  }
  ol, ul {
    padding-left: 15px;
  }
  .weitere-infos-wwwmarkuseiche {
    font-size: var(--font-size-sm);
  }
  .web-2600-1-main {
    padding-bottom: 5%;
  }
  .footer__wrapper {
    padding-bottom: 5%;
  }
  .sliding-text {
    width: 90%;
    height: 19%;
    top: 38%;
    left: 5%;
    right: 5%;
  }
  .sliding-div.video {
    font-size: var(--font-size-normal);
    padding: 0;
  }
  .greeting-text p {
    font-size: var(--font-size-normal);
    text-align: center;
  }
  .der-sternefotograf-markus, .panel p {
    font-size: var(--font-size-normal);
  }
  footer .footer__logo, footer p {
    text-align: center;
  }
  .row>[class*='col-']:not(.footer__col--nav) {
    padding-left: 10px;
    padding-right: 10px;
  }
  .green-info {
    font-size: var(--font-size-mobile);
  }
  .greeting-text p {
    width: 95%;
  }
  .icon-collection__item {
    padding-top: 10px;
  }
  .footer__col.footer__col--top-right {
    margin: 50px 0;
  }
  .green-video, .video {
    padding: 0 0.5rem;
  }
}
@media (max-width: 767.98px) {
  body {
      font-size: 18px;
      line-height: 1.4445;
      letter-spacing: .02em;
  }
  .footer__nav--sub-item a {
    display: block;
    padding: 10px 20px;
  }
  .footer__col--icon-collection {
    justify-content: space-around;
  }
}
@media (min-width: 768px) {
  .footer__nav--sub {
      display: block;
  }
  .logo-collection__item {
    max-width: calc(25% - 40px);
  }
}
@media (max-width: 1199.98px) {
  .footer__col--logo-collection {
      align-content: space-evenly;
      justify-content: center;
      padding: 0;
      display: none;
  }
  .footer__wrapper {
    margin-top: 10%;
  }
  .btn-play, .btn-replay, .btn-pause {
    margin-top: 27vw;
  }
  .footer__col--icon-collection {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .btn-play, .btn-replay, .btn-pause {
    width: 120px;
    margin-top: 310px;
  }
  .logo-collection__item {
      flex-basis: calc(25% - 40px);
      margin: 0 40px 30px 0;
  }
}
